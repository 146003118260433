<template>
  <b-container>
    <b-row class="mb-4 pt-5 p-3 d-flex justify-content-between text-start">
      <b-col><curva-title :title="$t('offers')"></curva-title></b-col>
      <dropdown-btn @select="getSelected" />
    </b-row>
    <b-row id="offers-list" class="mb-5 p-2" v-if="offeredProducts.length > 0">
      <b-col
        lg="3" md="6"
        v-for="(offer, key) in offeredProducts"
        :key="key"
      >
        <product-card :product="offer" />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col class="d-flex justify-content-center">
        <b-pagination
          class="curva-pagination"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          @input="getOffers();"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import DropdownBtn from '@/components/core/dropdown/dropdown-btn'
import OffersServices from '@/modules/offers/services/offers.services'
export default {
  components: { DropdownBtn },
  data () {
    return {
      offeredProducts: [],
      sortValue: 'id&dir=desc',
      perPage: 0,
      currentPage: 1,
      rows: 1
    }
  },
  methods: {
    getOffers () {
      OffersServices.getOffers(this.currentPage, this.sortValue).then((res) => {
        window.scrollTo(0, 0)
        this.rows = res.data.meta.total
        this.perPage = res.data.meta.per_page
        this.currentPage = res.data.meta.current_page
        this.offeredProducts = res.data.data
      })
    },
    getSelected (value) {
      this.currentPage = 1
      this.sortValue = value
      this.getOffers()
    }
  },
  created () {
    this.getOffers()
  },
  mounted () {
    core.index()
  }
}
</script>
